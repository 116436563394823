const storeName = process.env.GATSBY_CHECKOUT_STORE_NAME;
const checkoutApiUrl = process.env.GATSBY_CHECKOUT_API_URL;

export const getPixel = async props => {
  const {
    Checkout,
    upsell_url,
    currency_code,
    currency_symbol,
    shipping,
    freeGift,
    pixelIds,
    klaviyo_list,
    tracking
  } = props;

  //data object for getPixel function. This is temporary and needs to be improved asap
  const data = {
    store: storeName,
    checkout: checkoutApiUrl,
    upsell_url: upsell_url,
    currency: {
      code: currency_code,
      symbol: currency_symbol
    },
    onetime: {
      items: {
        onetime_1: {
          variant_id: 13700627922999,
          title: '1x Probiotic Chew',
          title_index: '1 Tub'
        },
        onetime_2: {
          variant_id: 13700653678647,
          title: '2x Probiotic Chews',
          title_index: '2 Tubs'
        },
        onetime_4: {
          variant_id: 13700656988215,
          title: '4x Probiotic Chew',
          title_index: '4 Tubs'
        }
      },
      discount_codes: ['onetimepet15usa'],
      discount_percentage: 15,
      variant_discount_percentage: {
        onetime_1: 15,
        onetime_2: 15,
        onetime_4: 15
      }
    },
    subscription: {
      items: {
        sub_1: {
          variant_id: 13700653023287,
          title: '1x Probiotic Chew - Monthly Subscription (Cancel Anytime)',
          title_index: '1 Tub'
        },
        sub_2: {
          variant_id: 13700655153207,
          title: '2x Probiotic Chew - Monthly Subscription (Cancel Anytime)',
          title_index: '2 Tubs'
        }
      },
      discount_codes: ['subpet25usa'],
      discount_percentage: 25,
      variant_discount_percentage: {
        sub_1: 25,
        sub_2: 25
      }
    },
    downSell: {},
    leader_discount_percentage: 25,
    shipping_fee: shipping,
    freeGift: freeGift,
    gift_for_otp: true,
    telephone: '+1 702-827-4738',
    pixel_ids: pixelIds,
    taboola_pixel: {
      id: '',
      eventname: ''
    },
    default_frequency: '',
    default_package_onetime: '',
    default_package_sub: '',
    available_frequency_option: ['onetime', 'subscription'],
    available_package_option: [
      'onetime_1',
      'onetime_2',
      'onetime_4',
      'sub_1',
      'sub_2'
    ],
    prod_box_title: '',
    pack_tub: '',
    top_image: '2',
    klaviyo_list: klaviyo_list,
    is_new_subscription: true
  };

  //config object for getPixel function. This is temporary and needs to be improved asap
  const config = {
    url: '/configs/itch25/default.json',
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*'
    },
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1
  };

  //temporary get pixel function - this needs to be improved
  await Checkout.getPixel(
    data,
    config,
    'index',
    tracking.utm_medium,
    tracking.utm_source
  );
};
