import styled from 'styled-components';

export const Button = styled.div`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  &.processing {
    padding: 10px;
    &:before {
      content: '';
      height: 20px;
      width: 100%;
      background-image: url('/images/three-dots.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > div {
      display: none;
    }
  }
`;
