import CheckoutSdk from '@petlab/checkout-sdk';
import { getPixel } from './pixel';

const Checkout = new CheckoutSdk(
  process.env.GATSBY_CHECKOUT_API_URL,
  process.env.GATSBY_CHECKOUT_STORE_NAME,
  process.env.GATSBY_CHECKOUT_API_KEY
);

const CheckoutStaging = new CheckoutSdk(
  process.env.GATSBY_CHECKOUT_API_URL_STAGING,
  process.env.GATSBY_CHECKOUT_STORE_NAME,
  process.env.GATSBY_CHECKOUT_API_KEY_STAGING
);

const store = process.env.GATSBY_CHECKOUT_STORE_NAME;

export const submitLocalCart = async props => {
  try {
    const {
      extraData,
      finalUpsellUrl,
      currency,
      freeGift,
      pixelIds,
      klaviyo_list,
      finalDiscountCodes,
      tracking
    } = props;

    const CheckoutAPI = extraData.sendToStagingCheckout
      ? CheckoutStaging
      : Checkout;

    // load pixel
    await getPixel({
      Checkout: CheckoutAPI,
      store: store,
      upsell_url: finalUpsellUrl,
      currency_code: currency.code,
      currency_symbol: currency.symbol,
      shipping: extraData.shipping,
      freeGift: freeGift,
      pixelIds: pixelIds,
      klaviyo_list: klaviyo_list,
      tracking: tracking,
      discountCodes: finalDiscountCodes
    });

    const {
      items,
      checkout,
      isNewSubscription,
      funnelUrl,
      extraPayload,
      freeSubscriptionGift,
      expeditedDeliveryPrice,
      expeditedDelivery,
      countriesAllowed,
      lang
    } = props;

    return await CheckoutAPI.createCheckout(
      checkout,
      items,
      finalDiscountCodes,
      finalUpsellUrl,
      tracking,
      extraData.shipping,
      pixelIds,
      klaviyo_list,
      isNewSubscription,
      freeSubscriptionGift,
      expeditedDeliveryPrice,
      expeditedDelivery,
      currency,
      countriesAllowed,
      lang,
      freeGift,
      funnelUrl,
      extraPayload
    );
  } catch (e) {
    console.log('error creating checkout!!');
    console.log(e);
  }
};
